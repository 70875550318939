  .nav {
    width: 90vw;
    margin: auto;
    background: #fffafa08;
    text-align: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    margin-top: 28px;
    border-radius: 0.675rem;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11vh;
    z-index: 10;
    transition: all 0.5s ease;
  }


  .country-headingg:hover {
    text-decoration: underline solid rgb(119, 96, 254) !important;
  }

  .nav-scrolled {
    width: 100vw;
    box-shadow: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.1);
    margin: 0;
  }

  .nav-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .nav-title {
    font-size: 32px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
  }

  .img-blur {
    animation: blur 7s infinite ease-in-out;
  }

  @keyframes blur {
    0% {
      filter: blur(35px);
    }

    50% {
      filter: blur(10px);
    }

    100% {
      filter: blur(35px);
    }
  }

  .main {
    padding-top: 26vh;
    margin: 0 10vh;
  }

  .search {
    width: 100%;
    margin-bottom: 11vh;
  }

  /* Grid */
  .countries {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2vw;
    margin-bottom: 5vh;
  }

  .card {
    backdrop-filter: blur(5px);
    min-height: 405px;
    max-height: max-content;
  }


  footer {
    background: #fffafa08;
    height: max-content;
    width: 100vw;
    font-family: Inter, sans-serif;
    padding: 20px;
    color: #fff;
    border-top: 1px solid #333;
    backdrop-filter: blur(10px);
  }

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .footer-content h3 {
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 3rem;
  }

  .footer-content p {
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 14px;
  }

  .totop {
    transition: all 0.5s ease;
  }

  .neighbor-icon {
    filter: invert();
  }

  .totop:hover {
    transform: scale(1.2);
  }

  .country-info-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  @media only screen and (max-width: 900px) {
    .country-info-container {
      grid-template-columns: auto auto;
    }

    .borderdiv {
      border: none !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .country-info-container {
      grid-template-columns: auto;
    }
  }